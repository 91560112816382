@import 'variables';
@import 'mixins';

/*CSS RESET*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/*HTML5 display-role reset for older browsers*/
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

html, body {
	width: 100%;
	height: 100%;
	background-color: $white;
	font-family: Open Sans, Arial, Sans-serif;
	font-size: 12px;
	color: $black;
	line-height: 1;

	@include custom1120 {
		font-size: 11px;
	}
	@include tablet {
		font-size: 10px;
	}
	@include custom320 {
		font-size: 9px;
	}
}

.wrapper {
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;
	height: auto;
	overflow: auto;
}

#scroll-down {
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  opacity: 0;
  cursor: pointer;
}
