@import 'variables';
@import 'mixins';

.contact {
	background: $off-white;
	padding: 70px 0;
	text-align: center;
	opacity: 1;

	&.delay {
    @include animation-delay(1s);
  }

	@include tablet {
		padding: 60px 0;
	}

	.sub-heading {
		margin: 20px 0 40px 0 !important;
	}

	svg {
		color: $grey;
		margin: 0 auto 30px auto;
	}

  h3 {
    text-align: center;
    color: $grey;
    font-size: 3em;
    font-weight: 400;
  }

  p {
    font-size: 1.6em;
    line-height: 1em;
    font-weight: 400;
    margin: 20px 0 30px 0;
    color: $blue;
  }

	a {
		padding: 10px 0;
		width: 160px;
		text-align: center;
		display: inline-block;
		background-color: transparent;
		border: 2px solid $blue;
		color: $blue;
		text-decoration: none;
		font-size: 1.4em;
		font-weight: 400;
		@include border-radius(.3em);
		@include transition(all ease-in-out .2s);

		&:not(:last-of-type) {
			margin-right: 30px;
		}

		&:hover {
			box-shadow: inset 180px 0px $blue;
			color: $off-white;
		}
	}
}