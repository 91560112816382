@import 'variables';
@import 'mixins';

.heading {
  padding: 70px 0;
  text-align: center;
  @include tablet {
    padding: 60px 0;
  }

  h3 {
    text-align: center;
    color: $grey;
    font-size: 3em;
    font-weight: 400;
  }

  p {
    margin: 20px 0 0 0;
  }
}

.projects {
  background: $off-white;

  &.delay {
    @include animation-delay(1s);
  }

  .fw-wrapper {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    overflow: auto;
    @include custom1320 {
      padding: 0 20px;
      @include box-sizing(border-box);
    }

    .project {
      width: 50%;
      float: left;
      height: auto;
      margin-bottom: 20px;
      @include box-sizing(border-box);
      @include mobile {
        width: 100%;
      }

      .project-inner {
        position: relative;
        overflow: hidden;

        .project-image {
          width: 100%;
          height: auto;
          position: relative;
          @include transition(transform ease-in-out .6s);

          picture {
            img {
              display: block;
              width: 100%;
              height: auto;
            }
          }

          .overlay {
            background: rgba(0, 0, 0, .1);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
        }

        .project-content {
          padding: 0 20px 20px;
          width: 100%;
          position: absolute;
          bottom: -36px;
          @include box-sizing(border-box);
          @include transition(bottom ease-in-out .3s);
          @include tablet {
            padding: 0 10px 10px;
            bottom: -26px;
          }
          @include custom400 {
            bottom: 0;
          }

          h4 {
            color: $off-white;
            font-size: 2.6em;
            font-weight: 600;
            @include font-smoothing(antialiased);
            @include mobile {
              width: calc(100% - 140px);
              font-size: 2em;
            }
            @include custom400 {
              width: 100%;
              font-size: 1.8em;
            }
          }

          h5 {
            color: $off-white;
            font-size: 1.2em;
            line-height: 1.4em;
            text-transform: uppercase;
            margin: 10px 0 15px;
            font-weight: 400;
            @include font-smoothing(antialiased);
            @include mobile {
              margin: 10px 0;
            }
          }

          span {
            display: inline-block;
            color: $off-white;
            text-decoration: none;
            font-size: 1.4em;
            font-weight: 400;
          }
        }

        &:hover {
          .project-content {
            bottom: 0;
          }
          .project-image {
            @include transform(scale(1.05));
          }
        }
      }

      &.full-width {
        width: 100%;
      }

      &.left {
        padding: 0 10px 0 0;
        @include mobile {
          padding: 0;
        }
      }

      &.right {
        padding: 0 0 0 10px;
        float: right;
        @include mobile {
          padding: 0;
        }
      }
    }
  }
}
