@import 'variables';
@import 'mixins';

.header {
	width: 100%;
	height: auto;
	background: -webkit-linear-gradient(left top, $blue, #a5e2f5);
  background: -o-linear-gradient(bottom right, $blue, #a5e2f5);
  background: -moz-linear-gradient(bottom right, $blue, #a5e2f5);
  background: linear-gradient(to bottom right, $blue, #a5e2f5);
	position: relative;

	canvas {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		height: 100%;
	}

	header {
		width: 90%;
		height: auto;
		position: relative;
		text-align: center;
		padding: 180px 0 160px 0;
		display: block;
		opacity: 1;
		margin: 0 auto;
		z-index: 100;
		@include custom400 {
			padding: 120px 0 80px 0;
		}

		.intro1 {
			font-size: 1.6em;
			color: $off-white;
			font-family: Open Sans, Arial, Sans-serif;
			font-weight: 700;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			margin-bottom: 15px;
			display: block;
			@include animation-delay(.3s);
			@include font-smoothing(antialiased);
		}

		.intro2 {
			font-size: 5.6em;
			color: $off-white;
			font-weight: 900;
			text-transform: uppercase;
			margin-bottom: 15px;
			display: block;
			@include animation-delay(.6s);
			@include font-smoothing(antialiased);
			@include custom400 {
				font-size: 4.6em;
			}
		}

		.intro3 {
			font-size: 1.6em;
			line-height: 1.6em;
			color: $off-white;
			font-family: Open Sans, Arial, Sans-serif;
			font-weight: 700;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			display: block;
			@include animation-delay(.9s);
			@include font-smoothing(antialiased);
		}
	}
}