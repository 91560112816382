@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin box-sizing($sizing) {
  -webkit-box-sizing: $sizing;
     -moz-box-sizing: $sizing;
      -ms-box-sizing: $sizing;
          box-sizing: $sizing;
}
@mixin font-smoothing($smoothing) {
	-webkit-font-smoothing: $smoothing;
	   -moz-font-smoothing: $smoothing;
	    -ms-font-smoothing: $smoothing;
	        font-smoothing: $smoothing;
}
@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
     -moz-appearance: $appearance;
      -ms-appearance: $appearance;
          appearance: $appearance;
}
@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}
@mixin transform($transform) {
  -webkit-transform: $transform;
     -moz-transform: $transform;
      -ms-transform: $transform;
       -o-transform: $transform;
          transform: $transform;
}

// Animation Mixins
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
     -moz-animation-duration: $duration;
      -ms-animation-duration: $duration;
          animation-duration: $duration;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
     -moz-animation-fill-mode: $fill-mode;
      -ms-animation-fill-mode: $fill-mode;
          animation-fill-mode: $fill-mode;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
     -moz-animation-delay: $delay;
      -ms-animation-delay: $delay;
          animation-delay: $delay;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
     -moz-animation-name: $name;
      -ms-animation-name: $name;
          animation-name: $name;
}
@mixin custom1320 {
  @media (max-width:1320px) {
    @content;
  }
}
@mixin custom1120 {
  @media (max-width:1120px) {
    @content;
  }
}
@mixin desktop {
	@media (max-width:960px) {
		@content;
	}
}
@mixin tablet {
	@media (max-width:768px) {
		@content;
	}
}
@mixin mobile {
	@media (max-width:670px) {
		@content;
	}
}
@mixin custom500 {
  @media (max-width:500px) {
    @content;
  }
}
@mixin custom400 {
  @media (max-width:400px) {
    @content;
  }
}
@mixin custom320 {
  @media (max-width:320px) {
    @content;
  }
}

@include keyframes(fadeIn) { from { opacity: 0; } to { opacity: 1; } }

.fade-in {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  @include animation-fill-mode(forwards);
  @include animation-duration(1s);
}

@include keyframes(fadeInUp) { 0% { opacity: 0; @include transform(translate3d(0, 100%, 0)); } 100% { opacity: 1; @include transform(none); }}

.fade-in-up {
  opacity: 0;
  @include animation-name(fadeInUp);
  @include animation-fill-mode(forwards);
  @include animation-duration(1s);
}