@import 'variables';
@import 'mixins';

footer {
	width: 100%;
	height: auto;
	padding: 20px 0;
	background-color: $grey;
	text-align: center;
	@include box-sizing(border-box);

	&.delay {
    @include animation-delay(1s);
  }

	p {
		color: $off-white;
		font-size: 1.2em;

		a {
			color: $off-white;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&:not(:last-of-type) {
			margin-bottom: 15px;
		}
	}
}